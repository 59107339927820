<template>
    <div class="single-choice" v-if="['SINGLE_CHOICE', 'JUDGMENT'].includes(question.type)">
        <van-radio-group v-model="question.userAnswer" direction="horizontal">
            <van-radio
                checked-color="#df7e2f"
                :disabled="isResult"
                :name="opt.title"
                v-for="opt in question.options"
                :key="opt.id"
            >
                {{ opt.title + '. ' + opt.content }}
            </van-radio>
        </van-radio-group>
    </div>
    <div class="multiple-choice" v-else-if="question.type === 'MULTIPLE_CHOICE'">
        <van-checkbox-group v-model="question.userAnswer" direction="horizontal">
            <van-checkbox
                checked-color="#df7e2f"
                :disabled="isResult"
                shape="square"
                :name="opt.title"
                v-for="opt in question.options"
                :key="opt.id"
            >
                {{ opt.title + '. ' + opt.content }}
            </van-checkbox>
        </van-checkbox-group>
    </div>
</template>
<script>
export default {
    props: ['question', 'isResult'],
}
</script>
<style lang="scss" scoped>
.multiple-choice,
.single-choice {
    margin: 10px 10px 0;
    ::v-deep .van-checkbox-group,
    ::v-deep .van-radio-group {
        > div {
            width: 50%;
            margin: 0 0 12px 0;
            font-size: 14px;
        }
        .van-radio__icon,
        .van-checkbox__icon {
            font-size: 14px;
        }
    }
}
</style>
