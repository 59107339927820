<template>
    <div
        ref="questionCard"
        class="question-card"
        :style="`transform: translate( ${questionCardRight}px, ${questionCardTop}px);`"
    >
        <div class="title">
            <span>
                答题卡
                <img src="@/assets/images/circular-icon.png" />
            </span>
            <div class="question-status" v-if="!isResult">
                <span class="finish">已答</span>
                <span class="unfinish">未答</span>
            </div>
            <div class="question-status" v-else>
                <span class="success">正确</span>
                <span class="error">错误</span>
            </div>
        </div>
        <div class="divider"></div>
        <div class="question-module">
            <div
                class="module-item"
                :class="mItem.id === 1 ? 'active' : ''"
                v-for="mItem in moduleQuestionList"
                :key="mItem.id"
            >
                <div @click="onChangeModule(mItem)" class="module-title" :title="mItem.title">{{ mItem.title }}</div>
                <div class="module-container">
                    <template v-for="item in mItem.questions">
                        <div
                            v-if="item.questionIndex"
                            :key="item.id"
                            class="question-index"
                            @click="onChangeQuestion(item)"
                        >
                            <QuestionCardIndex :question="item" />
                        </div>
                        <div
                            v-for="childItem in item.childQuestions"
                            :key="childItem.id"
                            v-else-if="item.childQuestions"
                            class="question-index"
                            @click="onChangeQuestion(item, childItem)"
                        >
                            <QuestionCardIndex :question="childItem" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <div class="answer-btn btn-style1" v-if="isResult && !examVip" @click="onShowAnswerToast">查看答案解析</div>
        <div class="submit-btn btn-style1" v-if="!isResult" @click="onSubmit">立即提交</div>
        <div class="submit-btn btn-style1" v-else @click="onReset">继续练习</div>
    </div>
</template>
<script>
import QuestionCardIndex from './QuestionCardIndex'
import { commitAnswer } from '@/api/paperApi.js'

export default {
    name: 'questionCard',
    props: ['moduleQuestionList', 'paperInfo', 'complexType', 'isResult', 'examVip'],
    data() {
        return {
            questionCardTop: 88,
            questionCardRight: 0,
        }
    },
    components: { QuestionCardIndex },

    mounted() {
        this.onresize = () => {
            const num = window.innerWidth - 1000
            this.questionCardRight = num > 0 ? -(num / 2) : 0
        }
        this.onresize()
        window.addEventListener('resize', this.onresize)
        this.onscroll = () => {
            if (window.scrollY <= 88) {
                this.questionCardTop = 88 - window.scrollY
            } else {
                this.questionCardTop = 0
            }
        }
        window.addEventListener('scroll', this.onscroll)
    },
    destroyed() {
        window.removeEventListener('resize', this.onresize)
        window.removeEventListener('scroll', this.onscroll)
    },
    methods: {
        onChangeModule(item) {
            this.$emit('module', item)
        },
        onChangeQuestion(item, childItem) {
            this.$emit('question', item, childItem)
        },
        getCommitAnswerData() {
            const { moduleQuestionList, complexType } = this
            const userAnswerList = []
            const setUserAnswerList = (item) => {
                const userAnswer = {
                    questionId: item.id,
                }
                if (typeof item.userAnswer === 'string') {
                    userAnswer.answer = item.userAnswer
                } else {
                    userAnswer.answer = item.userAnswer ? item.userAnswer.join('@@') : ''
                }
                userAnswerList.push(userAnswer)
            }
            moduleQuestionList.forEach((mItem) => {
                mItem.questions.forEach((item) => {
                    if (complexType.includes(item.type)) {
                        item.childQuestions.forEach((child) => {
                            setUserAnswerList(child)
                        })
                    } else {
                        setUserAnswerList(item)
                    }
                })
            })
            return userAnswerList
        },
        onShowAnswerToast() {
            this.$dialog.alert({
                message: '此功能仅限参加正式课程用户使用',
            })
        },
        onReset() {
            this.$router.push(`/paperDetails/${this.paperInfo.paperNo}`)
        },
        onSubmit() {
            const userAnswerList = this.getCommitAnswerData()
            let hasAnswerNum = 0
            userAnswerList.forEach((item) => {
                if (item.answer) hasAnswerNum += 1
            })
            if (hasAnswerNum === 0) {
                return this.$toast('请先作答试卷，再提交')
            } else {
                let message = '已全部作答完成，是否提交试卷？'
                if (hasAnswerNum !== userAnswerList.length) {
                    message = '试卷中还有未作答的试题，是否继续提交试卷？'
                }
                this.$dialog
                    .confirm({
                        message: message,
                    })
                    .then(() => {
                        // 过滤未作答试题
                        const newAnswerList = userAnswerList.filter((item) => !!item.answer)
                        commitAnswer(this.paperInfo.paperNo, newAnswerList).then(() => {
                            this.$toast.success('提交成功')
                            this.$router.push(`/paperResult/${this.paperInfo.paperNo}`)
                        })
                    })
                    .catch(() => {})
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.question-card {
    position: fixed;
    width: 240px;
    top: 0;
    right: 0;
    background: #ffffff;
    max-height: 84vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 9;
    .title {
        text-align: center;
        font-size: 20px;
        color: #33312f;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        margin: 0 20px;
        flex-shrink: 0;
        > span {
            position: relative;
            display: inline-block;
            img {
                background: none;
                position: absolute;
                bottom: 0;
                right: -12px;
                width: 20px;
                height: 20px;
            }
        }
        .question-status {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #99948d;
            span {
                display: flex;
                align-items: center;
            }
            .success,
            .finish {
                margin-right: 8px;
            }
            .unfinish::after,
            .finish::after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: $theme-color;
                border: 1px solid $theme-color;
                margin-left: 4px;
            }
            .unfinish::after {
                background: none;
            }

            .success::after,
            .error::after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: $paper-correct-color;
                border: 1px solid $paper-correct-color;
                margin-left: 4px;
            }
            .error::after {
                background: $paper-error-color;
                border-color: $paper-error-color;
            }
        }
    }
    .question-module {
        flex-grow: 1;
        overflow-y: scroll;
        .module-item.active {
            background: rgba($color: #333, $alpha: 0.14);
        }
        .module-title {
            overflow: hidden;
            margin: 0 22px;
            font-size: 16px;
            color: #66625e;
            white-space: nowrap;
            height: 48px;
            line-height: 48px;
            cursor: pointer;
            user-select: none;
        }
        .module-container {
            padding: 0 24px 0 12px;
            .question-index {
                display: inline-block;
                width: 28px;
                height: 28px;
                font-size: 13px;
                margin: 0 0 12px 12px;
            }
        }
    }
    .submit-btn {
        flex-shrink: 0;
        background: $theme-color;
        width: 156px;
        height: 40px;
        border-radius: 4px;
        margin: 16px auto;
    }
    .answer-btn {
        margin: 12px auto 0;
        flex-shrink: 0;
        width: 156px;
        height: 40px;
        border-radius: 4px;
        color: $theme-color;
        border: 1px solid $theme-color;
    }
    .divider {
        margin: 0 20px;
        border-bottom: 1px solid #e6e6e6;
    }
}
@media print {
    .question-card {
        display: none;
    }
}
</style>
