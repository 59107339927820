<template>
    <van-popup v-model="isShowPopup" @close="onClose" :duration="0.2" :close-on-click-overlay="false">
        <img class="cover" src="https://res.jiaoshi.fun/resource/20240725/0bedbf66500144dbac758b5d92d7b742.png" />
        <div class="desc">亲爱的学员，题库在电脑上使用才会有更好的体验哦～</div>
        <div class="web-url">
            <div>我们的网址</div>
            <div>
                <textarea ref="webUrl">https://www.zsjiaoyu.com</textarea>
                <span @click="onCopy">复制</span>
            </div>
        </div>
        <van-button @click="onClose" plain color="#000000">我知道了</van-button>
    </van-popup>
</template>
<script>
export default {
    data() {
        return {
            isShowPopup: true,
        }
    },
    methods: {
        onClose() {
            this.$router.push('/paper')
        },
        onCopy() {
            this.$nextTick(() => {
                this.$refs.webUrl.select()
                const state = document.execCommand('Copy')
                if (state) {
                    this.$toast('复制成功')
                } else {
                    this.$toast('复制失败，请在地址栏中复制')
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>

.van-popup {
    width: 300px;
    height: 380px;
    border-radius: 4px;
    .cover {
        width: 100%;
        height: 125px;
        display: block;
        background: none;
    }
    
    .desc {
        padding: 24px 16px 0;
        color: #333;
        font-size: 16px;
        text-align: center;
        line-height: 1.8em;
    }
    .web-url {
        margin: 12px 16px 24px;
        padding-top: 8px;
        background: #f8f8f8;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 16px;
        > div {
            padding-bottom: 12px;
            display: flex;
            align-items: center;
            color: #333;
            span {
                color: #666666;
            }
        }
        textarea {
            resize: none;
            background: none;
            border: none;
            color: $theme-color;
            line-height: 22px;
            height: 22px;
            font-size: 16px;
            padding: 0;
            width: 160px;
        }
    }
    .van-button {
        width: 104px;
        height: 44px;
        box-sizing: border-box;
        display: block;
        margin: 0 auto;
        border-radius: 4px;
    }
}
</style>
