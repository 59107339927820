<template>
    <span v-if="questionStatus === 'NULL'" class="question-status-0">
        {{ question.questionIndex }}
    </span>
    <span v-else-if="questionStatus === 'COMPLETE'" class="question-status-1">
        {{ question.questionIndex }}
    </span>
    <span v-else-if="questionStatus === 'UNCOMPLETE'" class="question-status-2">
        {{ question.questionIndex }}
    </span>
    
    <span v-else-if="questionStatus === 'CORRECT'" class="question-status-correct">
        {{ question.questionIndex }}
    </span>
    <span v-else-if="questionStatus === 'PARTCORRECT'" class="question-status-part-correct">
        {{ question.questionIndex }}
    </span>
    
    <span v-else-if="questionStatus === 'ERROR'" class="question-status-error">
        {{ question.questionIndex }}
    </span>
</template>
<script>
export default {
    name: 'questionCard',
    props: ['question'],
    watch: {
        'question.userAnswer': function() {
            this.setQuestionStatus()
        },
    },
    created() {
        this.setQuestionStatus()
    },
    data() {
        return {
            // NULL:未作答 UNCOMPLETE:未完成 COMPLETE:完成
            // CORRECT: 答题正确 ERROR: 答题错误 PARTCORRECT: 部分正确
            questionStatus: 'NULL',
        }
    },
    methods: {
        setQuestionStatus() {
            if (this.question.userAnswerState === true) {
                this.questionStatus = 'CORRECT'
            } else if (this.question.userAnswerState === false) {
                this.questionStatus = 'ERROR'
                if (this.question.userScore !== 0 && this.question.userScore !== this.question.score) {
                    this.questionStatus = 'PARTCORRECT'
                }
            } else if (this.question.userAnswer instanceof Array) {
                let status = 0
                this.question.userAnswer.forEach(item => {
                    if (item) status = status + 1
                })
                if (status === 0) {
                    this.questionStatus = 'NULL'
                } else if (status === this.question.userAnswer.length) {
                    this.questionStatus = 'COMPLETE'
                } else {
                    this.questionStatus = 'UNCOMPLETE'
                }
            } else if (this.question.userAnswer) {
                this.questionStatus = 'COMPLETE'
            } else {
                this.questionStatus = 'NULL'
            }
        },
    },
}
</script>
<style scoped lang="scss">
span {
    user-select: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $theme-color;
    box-sizing: border-box;
    color: #99948d;
    cursor: pointer;
}
.question-status-1 {
    color: #ffffff;
    background: $theme-color;
}
.question-status-2 {
    background: rgba($color: $theme-color, $alpha: 0.2);
}

.question-status-correct {
    color: #ffffff;
    background: $paper-correct-color;
    border: none;
}
.question-status-part-correct {
    color: #ffffff;
    background: rgba($color: $paper-correct-color, $alpha: 0.5);
    border: none;
}
.question-status-error {
    color: #ffffff;
    background: $paper-error-color;
    border: none;
}

</style>
