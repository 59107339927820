// 补0
export const supplementZero = function (num) {
    if (isNaN(num)) {
        num = 0
    }
    return num >= 10 ? num : `0${num}`
}

export const isWeChatClient = () => {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
    }
    return false
}

export const isIosEnv = () => {
    var u = navigator.userAgent
    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}
export const browserEnv = () => {
    const ua = navigator.userAgent.toLowerCase()
    // return 'pc'
    return /iPad|Android|webOS|iPhone|iPod|BlackBerry/i.test(ua) ? 'mobile' : 'pc'
}

// 课程最后观看位置
export const coursePosition = (orderNo, value) => {
    // ['menu', 'course', 'position']
    let val = JSON.parse(window.localStorage.getItem(`position_${orderNo}`)) || ['', '', '']
    if (value) {
        localStorage.setItem(`position_${orderNo}`, JSON.stringify(value))
    }
    return value || val
}

export const getDurationText = (time) => {
    let date = parseInt(time)
    if (!date && date !== 0) return '-'
    const h = parseInt(date / 3600)
    const m = parseInt((date % 3600) / 60)
    const s = date % 60

    return `${supplementZero(h)}:${supplementZero(m)}:${supplementZero(s)}`
}

// 过滤查询参数中的空值 空字符串
export const filterQueryEmpty = function (obj) {
    const newObj = {}
    for (const key in obj) {
        if (obj[key] || obj[key] === 0 || obj[key] === false) {
            newObj[key] = obj[key]
        }
    }
    return newObj
}

export const getAttendTime = (time) => {
    if (!time) return ''
    if (time / 3600 > 1) {
        const n = time / 3600
        return n.toFixed(1) + '小时'
    } else if (time / 60 > 1) {
        return Math.round(time / 60) + '分钟'
    } else {
        return ''
    }
}

export const getQuestionBlankConfig = (text) => {
    const _l = text ? text.match(/BLANK__\d+/g) || [] : []
    return _l.map((s) => parseInt(s.split('__')[1]) || 100)
}
