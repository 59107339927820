<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="question-title question-blank" v-if="blankType.includes(question.type)">
        <div>{{ question.questionIndex }}.</div>
        <div class="content">
            <span v-for="(title, blankIndex) in question.titleList" :key="blankIndex + title">
                <span>{{ title }}</span>
                <template v-if="blankIndex < question.titleList.length - 1">
                    <input
                        :disabled="isResult"
                        placeholder="请输入"
                        v-model="question.userAnswer[blankIndex]"
                        :style="`width: ${question.blankConfig && question.blankConfig[blankIndex]}px`"
                    />
                </template>
            </span>
        </div>
    </div>
    <div v-else-if="question.type === 'WRITING'">
        <div class="question-title">
            <div class="index">{{ question.questionIndex }}.</div>
            <Markdown class="content" :text="question.content" />
        </div>
        <ImagePreview :picUrl="question.picUrl" v-if="question.picUrl" />
        <textarea v-if="isResult" class="writing-container readonly" readonly>
            默认作答正确，请查看正确答案(参考答案)
        </textarea>
        <!-- eslint-disable-next-line vue/no-mutating-props -->
        <textarea v-else v-model="question.userAnswer" class="writing-container"></textarea>
    </div>
    <div class="question-title" v-else-if="question.type === 'EREADING_COMPREHENSION'">
        <div class="content">
            <Markdown class="text-indent" :text="question.content" />
            <ImagePreview :picUrl="question.picUrl" v-if="question.picUrl" />
        </div>
    </div>
    <div class="question-title" v-else-if="question.type === 'LISTENING'">
        <Audio
            :question="{
                parentType: 'LISTENING',
                parentTitle: question.title,
                parentContent: question.content,
                parentPicUrl: question.picUrl,
            }"
        />
    </div>
    <div v-else-if="!complexType.includes(question.type)">
        <div class="question-title format-title">
            <span class="index">{{ question.questionIndex }}. </span>
            <Markdown :text="question.content" />
        </div>
        <ImagePreview :picUrl="question.picUrl" v-if="question.picUrl" />
    </div>
</template>
<script>
import Audio from './Audio.vue'
import Markdown from '@/components/Markdown'
import ImagePreview from './ImagePreview.vue'

export default {
    props: ['question', 'blankType', 'complexType', 'isResult'],
    components: { Audio, Markdown, ImagePreview },
}
</script>
<style lang="scss" scoped>
.question-title {
    display: flex;
    font-size: 15px;
    padding: 8px 0;
    line-height: 1.6em;
    color: #334;
    position: relative;
    .index {
        flex-shrink: 0;
    }
    .content {
        flex-grow: 1;
        max-height: 60vh;
        overflow-y: auto;
    }
    .text-indent {
        text-indent: 2em;
        ::v-deep p {
            margin-bottom: 6px;
        }
    }
}

.question-blank {
    input {
        border: none;
        border-bottom: 1px solid #ccc;
        width: 80px;
        min-width: 80px;
        padding: 0 10px;
        height: 1.4em;
        margin: 0 10px;
        background: none;
        &:focus {
            border-bottom: 1.5px solid #409eff;
        }
        &:disabled {
            cursor: not-allowed;
        }
    }
}
.writing-container {
    width: 96%;
    margin: 0 auto 12px;
    display: block;
    min-height: 120px;
    max-height: 300px;
    border: 1px solid #ccc;
    resize: none;
    border-radius: 3px;
    line-height: 1.4em;
    padding: 12px;
    box-sizing: border-box;
    font-size: 14px;
}
.readonly {
    color: #9a9a9a;
    cursor: no-drop;
}
</style>
