<template>
    <div class="pic-url">
        <img
            :class="{ fullImg: item.isFull }"
            @click="onFullImageUrl(item)"
            :src="item.url"
            v-for="item in picUrlList"
            :key="item.url"
        />
    </div>
</template>
<script>
export default {
    props: ['picUrl'],
    data() {
        return {
            picUrlList: [],
        }
    },
    created() {
        this.picUrlList = []
        this.picUrl.split(',').forEach((img) => {
            this.picUrlList.push({ url: img, isFull: true })
        })
    },
    methods: {
        onFullImageUrl(item) {
            item.isFull = !item.isFull
        },
    },
}
</script>
<style lang="scss" scoped>
.pic-url {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    img {
        width: auto;
        height: 120px;
        object-fit: cover;
        margin-right: 20px;
        margin-bottom: 20px;
        flex-shrink: 0;
        cursor: pointer;
    }
    .fullImg {
        width: 100%;
        margin-right: 0;
        height: auto;
    }
}
</style>
